import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { userStore } from "@/store/userStore";

export default async function (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    let { user, authenticate } = userStore();
    if (to.meta.hasOwnProperty('isLoggedIn')) {
        if (!user.isLoggedIn) {
            await authenticate().then((d) => {
                next(true);
            }).catch((e) => {
                next('/auth');
            });
        } else {
            next(true);
        }
    } else {
        next(true);
    }
}
