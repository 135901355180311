<template>
    <router-view />
    <notifications position="bottom right" />
    <modal-wrapper modal-name="confirm_modal" />
    <modal-wrapper modal-name="check_card_modal" />
    <modal-wrapper modal-name="entrance_a_e_modal" />
    <modal-wrapper modal-name="turnstile_a_e_modal" />
    <modal-wrapper modal-name="human_a_e_modal" />
    <modal-wrapper modal-name="reject_moderation_modal" />
    <modal-wrapper modal-name="human_info_modal" />
    <modal-wrapper modal-name="reset_password_modal" />
    <modal-wrapper modal-name="human_step_one_e_modal" />
    <modal-wrapper modal-name="human_step_two_e_modal" />
    <modal-wrapper modal-name="human_step_three_e_modal" />
    <modal-wrapper modal-name="o_v_p_o_a_e_modal" />
    <modal-wrapper modal-name="o_v_p_o_reason_modal" />
    <modal-wrapper modal-name="monitoring_modal" />
    <modal-wrapper modal-name="institution_a_e_modal" />
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent, onMounted, ref } from "vue";

import ModalWrapper from "@/components/common/ModalWrapper.vue";
import { socketOn } from "@/composables/useSocket";
import api from "@/api";
import { useModal, isOpen } from "@/composables/useModal";
import useNotifications from "@/composables/useNotifications";

export default defineComponent({
    name: "App",
    setup() {
        const registration: any = ref(null);
        const updateExists: any = ref(false);
        const refreshing: any = ref(false);

        onMounted(() => {
            document.addEventListener('swUpdated', updateAvailable, { once: true });
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (refreshing.value) return;
                refreshing.value = true;
                window.location.reload();
            });

            socketOn('placeCard', (r) => {
                if (!isOpen('human_a_e_modal')) {
                    useModal('check_card_modal').close();
                    api.v1.humans.checkCard(r.code).then((r: any) => {
                        useModal('check_card_modal').open({
                            human: r.data.data,
                        });
                    }).catch((e: any) => {
                        if (['human_not_exist', 'human_exist'].includes(e.data.error.code)) {
                            useModal('check_card_modal').open({
                                errorCode: e.data.error.code,
                            });
                        } else {
                            useNotifications.error('Ошибка', 'Ошибка проверки карточки');
                        }
                    });
                }
            });
        });

        function updateAvailable(event: any) {
            registration.value = event.detail;
            updateExists.value = true;
            refreshApp();
        }

        function refreshApp() {
            updateExists.value = false;
            if (!registration.value || !registration.value.waiting) return;
            registration.value.waiting.postMessage({ type: 'SKIP_WAITING' });
        }

        return {

        };
    },
    components: {
	    ModalWrapper,
    },
});
</script>

<style scoped lang="scss">

</style>
