/* eslint-disable no-console */

import { register } from 'register-service-worker';
//
// console.log(11111111111111111111111, process.env.PACKAGE_VERSION);
//
// caches.keys().then(function (names) {
//     for (let name of names) {
//         console.log(name);
//     }
//     // caches.delete(name);
// });

// self.addEventListener('activate', function (event: any) {
//     event.waitUntil(
//         caches.keys().then(function (cacheNames) {
//             return Promise.all(
//                 cacheNames.filter(function (cacheName) {
//                 }).map(function (cacheName) {
//                     return caches.delete(cacheName);
//                 }),
//             );
//         }),
//     );
// });

// function checkUpdate() {
//     fetch('/api/v1/info/version').then((response) => response.json()).then((r) => {
//         if (r.ok) {
//             if (r.data.version !== process.env.PACKAGE_VERSION) {
//                 console.log('надо очиститься');
//                 let response = fetch('https://api.telegram.org/bot1170098016:AAEgQEj825w0eCbx84BBzYHk5oyfIOGx8sY/sendMessage', {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json;charset=utf-8',
//                     },
//                     body: JSON.stringify({
//                         chat_id: -620929490,
//                         text: 'надо очиститься',
//                         parse_mode: 'HTML',
//                     }),
//                 });
//             }
//         }
//     });
// }

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log(
                'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB',
            );
            // checkUpdate();
        },
        registered() {
            console.log('Service worker has been registered.');
        },
        cached() {
            console.log('Content has been cached for offline use.');
        },
        updatefound() {
            console.log('New content is downloading.');
        },
        updated(registration) {
            console.log('New content is available; please refresh.');
            console.log('надо обновиться');
            // checkUpdate();
            setTimeout(() => {
                console.log('жоское обновление');
                //@ts-ignore
                window.location.reload(true);
            }, 1000);
            // document.dispatchEvent(
            //     new CustomEvent('swUpdated', { detail: registration }),
            // );
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.');
        },
        error(error) {
            console.error('Error during service worker registration:', error);
        },
    });
}
