import { userStore } from "@/store/userStore";
import { App } from "vue";

const { user } = userStore();

export default {
    install: (app: App) => {
        //@ts-ignore
        app.config.globalProperties.$user = user;
    },
};
