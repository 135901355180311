import { api } from '@/api/index';
import { IUnknownObject } from "@/@types/common";

interface ISignUp {
    phone: string,
    password: string,
    ownershipType: number,
    bin: string,
    name: string,
    stat: object
}

interface IEntranceCreate extends IUnknownObject{
    name: string,
    cityId: number,
    capacity: number,
    address: string,
    login: string,
    password: string,
    firstName: string,
    lastName: string,
    middleName: string | null,
    iin: string,
    phone: string
}

interface ITurnstileCreate extends IUnknownObject{
    name: string,
    sn: string,
    entranceId: number,
    reverseDirection: boolean
}

export default {
    v1: {
        auth: {
            login: async (login: string, password: string) => await api.post('/api/v1/auth/login', { login, password }),
            me: async () => await api.get('/api/v1/auth/me'),

            signUp: async (data: ISignUp) => await api.post('/api/v1/auth/signup', data),
            sendSms: async (phone: string) => await api.post('/api/v1/auth/send_sms', { phone }),
            verifySms: async (phone: string, code: string) => await api.post('/api/v1/auth/verify_sms', { phone, code }),
            changePassword: async (phone: string, code: string, password: string) => await api.post('/api/v1/auth/change_password', { phone, code, password }),
        },
        humans: {
            index: async (params: IUnknownObject) => await api.get('/api/v1/humans?' + new URLSearchParams(params).toString()),
            create: async (params: IUnknownObject) => await api.post('/api/v1/humans', params, { headers: { "Content-Type": "multipart/form-data" } }),
            linkCard: async (id: number, params: IUnknownObject) => await api.post('/api/v1/humans/' + id + '/link_card', params),
            update: async (id: number, params: IUnknownObject) => await api.patch('/api/v1/humans/' + id, params, { headers: { "Content-Type": "multipart/form-data" } }),
            lostCard: async (id: number) => await api.post('/api/v1/humans/' + id + '/lost_card'),
            setActive: async (id: number, state: boolean) => await api.post('/api/v1/humans/' + id + '/set_active', {
                active: state,
            }),
            remove: async (id: number) => await api.delete('/api/v1/humans/' + id),
            checkCard: async (code: string) => await api.post('/api/v1/humans/check_card', {
                code: code,
            }),
            updateStepOne: async (id: number, params: IUnknownObject) => await api.patch('/api/v1/humans/' + id + '/step_one', params),
            updateStepTwo: async (id: number, params: IUnknownObject) => await api.patch('/api/v1/humans/' + id + '/step_two', params, { headers: { 'Content-type': 'multipart/form-date', }}),
            updateStepThree: async (id: number, params: IUnknownObject) => await api.patch('/api/v1/humans/' + id + '/step_three', params, { headers: { 'Content-type': 'multipart/form-date', }}),
            checkIin: async (iin: string, id = 0) => await api.post('/api/v1/humans/check_iin', { iin: iin, id: id }),
            getDeleted: async (params: IUnknownObject) => await api.get('/api/v1/humans/deleted?' + new URLSearchParams(params).toString()),
        },
        users: {
            index: async (params: IUnknownObject) => await api.get('/api/v1/users?' + new URLSearchParams(params).toString()),
            resetPassword: async (id: number) => await api.post('/api/v1/password_recovery/generate', { userId: id }),
            changePassword: async (password: string, token: string) => await api.post('/api/v1/password_recovery/reset_password/', { newPassword: password, token: token }),
            validateToken: async (params: IUnknownObject) => await api.get('/api/v1/password_recovery/validate_token?' + new URLSearchParams(params).toString()),
        },
        entrances: {
            index: async () => await api.get('/api/v1/entrances'),
            create: async (data: IEntranceCreate) => await api.post('/api/v1/entrances', data),
            update: async (id: number, data: IUnknownObject) => await api.patch('/api/v1/entrances/' + id, data),
            checkLogin: async (data: IUnknownObject) => await api.post('/api/v1/entrances/check_login', data),
            remove: async (id: number) => await api.delete('/api/v1/entrances/' + id),
        },
        turnstiles: {
            index: async () => await api.get('/api/v1/turnstiles'),
            create: async (data: ITurnstileCreate) => await api.post('/api/v1/turnstiles', data),
            update: async (id: number, data: IUnknownObject) => await api.patch('/api/v1/turnstiles/' + id, data),
            remove: async (id: number) => await api.delete('/api/v1/turnstiles/' + id),
        },
        info: {
            cities: {
                get: async () => await api.get('/api/v1/info/cities'),
            },
            entrances: {
                get: async () => await api.get('/api/v1/info/entrances'),
            },
            universities: {
                get: async () => await api.get('/api/v1/info/universities'),
            },
            institutions: {
                get: async () => await api.get('/api/v1/info/institutions'),
                getAll: async () => await api.get('/api/v1/info/institutions_all'),
            },
            educations: {
                get: async () => await api.get('/api/v1/info/educations'),
            },
            humanRoles: {
                get: async () => await api.get('/api/v1/info/human_roles'),
            },
        },
        reports: {
            get: async (params: IUnknownObject) => await api.post('/api/v1/reports', params),
            excel: async (params: IUnknownObject) => await api.post('/api/v1/reports/excel', params, { responseType: 'arraybuffer' }),
        },
        moderation: {
            get: async () => await api.get('/api/v1/moderation'),
            approve: async (params: IUnknownObject) => await api.post('/api/v1/moderation/approve', params),
            reject: async (params: IUnknownObject) => await api.post('/api/v1/moderation/reject', params),
        },
        dashboard: {
            get: async () => await api.get('/api/v1/main/dashboard'),
        },
        activity: {
            get: async (params: IUnknownObject) => await api.post('/api/v1/main/activity', params),
        },
        ovpo: {
            get: async (roleId = 0) => await api.get('/api/v1/ovpo?id=' + roleId),
            create: async (params: IUnknownObject) => await api.post('/api/v1/ovpo', params),
        },
        ovpoReports: {
            get: async () => await api.get('/api/v1/ovpo_reports'),
            show: async (id: number) => await api.get('/api/v1/ovpo_reports/' + id),
            changeStudying: async (id: number, value: boolean) => await api.post('/api/v1/ovpo_reports/' + id + '/change_studying', { value: Number(value) }),
            changeLiving: async (id: number, value: boolean) => await api.post('/api/v1/ovpo_reports/' + id + '/change_living', { value: Number(value) }),
            changeComment: async (id: number, value: boolean) => await api.post('/api/v1/ovpo_reports/' + id + '/change_comment', { value: Number(value) }),
            moderation: async (id: number) => await api.post('/api/v1/ovpo_reports/' + id + '/moderation'),
            reject: async (id: number) => await api.post('/api/v1/ovpo_reports/' + id + '/reject'),
            download: async (id: number) => await api.post('/api/v1/ovpo_reports/' + id + '/download', {}, { responseType: 'arraybuffer' }),
            getXml: async (id: number) => await api.post('/api/v1/ovpo_reports/' + id + '/get_xml'),
            sign: async (id: number, params: any) => await api.post('/api/v1/ovpo_reports/' + id + '/sign', params),
        },
        institutions: {
            index: async (params: IUnknownObject) => await api.get('/api/v1/institutions?' + new URLSearchParams(params).toString()),
            create: async (params: IUnknownObject) => await api.post('/api/v1/institutions', params),
            update: async (id: number, params: IUnknownObject) => await api.patch('/api/v1/institutions/' + id, params),
            remove: async (id: number) => await api.delete('/api/v1/institutions/' + id),
        },
    },
};
