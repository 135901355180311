import axios, { AxiosRequestConfig, AxiosError, AxiosResponse, AxiosInstance, AxiosRequestHeaders } from "axios";
import { log } from "@/utils/log";
import { API_URL } from "@/configs/app";
import { i18n } from "@/composables/useLocale";
import apis from './apis';
import useNotifications from "@/composables/useNotifications";
import router from '@/router/index';
import { socket } from '@/composables/useSocket';

export const api: AxiosInstance = axios.create({
    timeout: 120000,
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        // 'Authorization': 'Bearer ' + token.value,
    },
});

api.interceptors.request.use(onRequest, onRequestError);
api.interceptors.response.use(onResponse, onResponseError);

export function onRequest(config: any) {
    const token = localStorage.getItem('token');
    const data = (typeof config.data !== 'undefined') ? JSON.parse(JSON.stringify(config.data)) : 'No Data';
    config.headers['X-AUTH-TOKEN'] = token;
    config.headers['X-SOCKET-ID'] = socket.value.id || null;
    config.headers['Accept-Language'] = i18n.locale;
    log('api:onRequest:' + config.url, data);
    return config;
}

export function onRequestError(error: AxiosError) {
    log('api:onRequestError', error);
}

export function onResponse(response: AxiosResponse) {
    log('api:onResponse:' + response.config.url, response.data);
    return response;
}

export function onResponseError(error: any) {
    log('api:onResponseError', error);

    if (error.hasOwnProperty('response')) {
        let response = error.response;

        if (response.data.ok === false) {
            if (typeof response.data.error !== 'undefined') {
                if (typeof response.data.error.code !== 'undefined') {
                    if (response.data.error.code === 'unauthorized') {
                        location.href = '/auth?redirect_to=' + location.pathname;
                    }
                    if (response.data.error.code === 'server_error') {
                        router.push('/500');
                    }

                    if (response.data.error.code === 'access_denied') {
                        router.push('/403');
                    }

                    if (response.data.error.code === 'not_implemented') {
                        console.log('not_implemented');
                        useNotifications.error('Запрос не обработан', response.data.error.msg);
                    }

                    if (response.data.error.code === 'not_found') {
                        router.push('/500');
                    }
                }
            }
        } else if (response.status === 404) {
            // TODO: лог в телегу
            useNotifications.error('Not Found', 'Запрос не найден');
        }
    } else {
        useNotifications.error('Error', 'Ошибка запроса');
    }

    return Promise.reject(error.response);
}

export default apis;
