import { computed, reactive, readonly, ComputedRef } from "vue";
import axios, { AxiosPromise } from "axios";
import api from '@/api';
import { log } from "@/utils/log";
import { IUnknownObject } from '@/@types/common';
import useColor from "@/composables/useColor";
import getSumAllStringNumbers from "@/utils/getSumAllStringNumbers";

// Временные константы ролей

export interface IUserState {
    id: number,
    phone: string,
    firstName: string | null,
    lastName: string | null,
    middleName: string | null,
    roleId: number,
    fill: string,
    color: string,
    placeCardLink: string,
    permissions: string[],
    isLoggedIn: ComputedRef,
    isSuperAdmin: ComputedRef,
    isAdmin: ComputedRef,
    isManager: ComputedRef,
    isUser: ComputedRef,
    isOVPOSignatory: ComputedRef,
    isOVPOOperator: ComputedRef,
    isAudit: ComputedRef,

    is(permission: string): boolean,
    not(permission: string): boolean,
}

const state: IUserState = reactive(<IUserState>{
    id: 0,
    phone: '',
    firstName: '',
    lastName: '',
    middleName: '',
    roleId: 0,
    fill: '#222e3c',
    color: '#fff',
    placeCardLink: '',
    permissions: [],
    isLoggedIn: computed((): boolean => state.id !== 0),
    isSuperAdmin: computed((): boolean => state.roleId === 1),
    isAdmin: computed((): boolean => state.roleId === 2),
    isManager: computed((): boolean => state.roleId === 3),
    isUser: computed((): boolean => state.roleId === 4),
    isOVPOSignatory: computed((): boolean => state.roleId === 5),
    isOVPOOperator: computed((): boolean => state.roleId === 6),
    isAudit: computed((): boolean => state.roleId === 7),
    is: (permission: string): boolean => state.permissions.includes(permission),
    not: (permission: string): boolean => !state.permissions.includes(permission),
});

function updateStore(args: IUnknownObject) {
    if (args.hasOwnProperty('id')) state.id = args.id;
    if (args.hasOwnProperty('phone')) state.phone = args.phone;
    if (args.hasOwnProperty('firstName')) state.firstName = args.firstName;
    if (args.hasOwnProperty('lastName')) state.lastName = args.lastName;
    if (args.hasOwnProperty('middleName')) state.middleName = args.middleName;
    if (args.hasOwnProperty('roleId')) state.roleId = args.roleId;
    if (args.hasOwnProperty('permissions')) state.permissions = args.permissions;
    if (args.hasOwnProperty('placeCardLink')) state.placeCardLink = args.placeCardLink;

    if (args.hasOwnProperty('phone')) {
        const { fill, color } = useColor(args.phone);
        state.fill = fill;
        state.color = color;
    }
}

function login(login: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
        return api.v1.auth.login(login, password).then(r => {
            if (r.data.ok) {
                localStorage.setItem('token', r.data.data.token);
                updateStore({ id: 0 });
                resolve(r.data);
            } else {
                reject(r.data);
            }
        }).catch(e => {
            reject(e.data);
        });
    });
}

function logout(): Promise<boolean> {
    return new Promise((resolve, reject) => {
        localStorage.removeItem('token');
        updateStore({ id: 0 });
        resolve(true);
    });
}

function authenticate(): AxiosPromise {
    log('userStore:authenticate');
    return new Promise((resolve, reject) => {
        return api.v1.auth.me().then(r => {
            if (r.data.ok) {
                updateStore(r.data.data);
                resolve(r.data);
            } else {
                reject(r.data);
            }
        }).catch(e => {
            reject(e.data);
        });
    });
}

export function userStore() {
    return {
        user: readonly(<IUserState>state),
        logout,
        login,
        authenticate,
    };
}
