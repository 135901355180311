import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_notifications = _resolveComponent("notifications")!
  const _component_modal_wrapper = _resolveComponent("modal-wrapper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _createVNode(_component_notifications, { position: "bottom right" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "confirm_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "check_card_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "entrance_a_e_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "turnstile_a_e_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "human_a_e_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "reject_moderation_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "human_info_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "reset_password_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "human_step_one_e_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "human_step_two_e_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "human_step_three_e_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "o_v_p_o_a_e_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "o_v_p_o_reason_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "monitoring_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "institution_a_e_modal" })
  ], 64))
}