import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import beforeEach from "@/router/beforeEach";
import { routes } from './routes';
// @ts-ignore
import NProgress from 'nprogress';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    NProgress.start();
    next();
});
router.beforeEach(beforeEach);

router.afterEach((to, from) => {
    NProgress.done();
});

export default router;
