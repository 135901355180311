import { setupDevtoolsPlugin } from '@vue/devtools-api';
import { userStore } from "@/store/userStore";
import { watch } from "vue";

let devtoolsApi: any;
const inspectorId = 'store';
const stores = ['userStore'];
const _userStore = userStore();

watch(() => _userStore, v => {
    if (devtoolsApi) {
        devtoolsApi.sendInspectorState(inspectorId);
    }
}, {
    deep: true,
    immediate: true,
});

export function setupDevtools(app: any) {
    const devtools = {
        // Our helpers here
    };

    setupDevtoolsPlugin({
        id: 'store-inspector',
        label: 'Store Inspector',
        packageName: 'store-inspector',
        homepage: 'https://vuejs.org',
        app,
    }, api => {
        api.addInspector({
            id: inspectorId,
            label: 'Store Inspector',
            icon: 'bug_report',
        });

        api.on.getInspectorTree((payload, context) => {
            if (payload.inspectorId === inspectorId) {
                payload.rootNodes = stores.map((i: any) => ({
                    id: i,
                    label: i,
                }));
            }
        });

        api.on.getInspectorState((payload, context) => {
            if (payload.inspectorId === inspectorId) {
                if (payload.nodeId === 'userStore') {
                    let store = userStore();
                    payload.state = Object.keys(userStore()).reduce((a, v) => {
                        // @ts-ignore
                        let value = store[v];
                        if (typeof value === 'object') {
                            return { ...a, [v]: value };
                        } else if (typeof value === 'function') {
                            return {
                                ...a,
                                [v]: {
                                    function: value,
                                },
                            };
                        }

                        return { ...a, [v]: value };
                    }, {});
                }
            }
        });

        devtoolsApi = api;
    });

    return devtools;
}

export function StoreInspector(app: any, options = {}) {
    if (process.env.NODE_ENV === 'development') {
        setupDevtools(app);
    }
}
