import { RouteRecordRaw } from "vue-router";
import { userStore } from "@/store/userStore";

const { user } = userStore();

export const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Index',
        component: () => {
            return import(/* webpackChunkName: "index" */ '../views/IndexView.vue');
        },
        meta: {
            title: 'Главная???',
            isLoggedIn: true,
        },
    },
    {
        path: '/auth',
        name: 'Auth',
        component: () => import(/* webpackChunkName: "auth" */ '../views/AuthView.vue'),
        meta: {
            title: 'titles.auth',
        },
    },
    {
        path: '/humans',
        name: 'HumanIndex',
        component: () => import(/* webpackChunkName: "humans-index" */ '../views/humans/IndexView.vue'),
        meta: {
            isLoggedIn: true,
        },
    },
    {
        path: '/users',
        name: 'UserIndex',
        component: () => import(/* webpackChunkName: "users-index" */ '../views/users/IndexView.vue'),
        meta: {
            isLoggedIn: true,
        },
    },
    {
        path: '/ovpo',
        name: 'OVPOIndex',
        component: () => import(/* webpackChunkName: "ovpo-index" */ '../views/ovpo/IndexView.vue'),
        meta: {
            isLoggedIn: true,
        },
    },
    {
        path: '/ovpo_reports',
        name: 'OVPOReportIndex',
        component: () => import(/* webpackChunkName: "ovpo-report-index" */ '../views/ovpo_reports/IndexView.vue'),
        meta: {
            isLoggedIn: true,
        },
    },
    {
        path: '/ovpo_reports/:id',
        name: 'OVPOReportShow',
        component: () => import(/* webpackChunkName: "ovpo-report-show" */ '../views/ovpo_reports/ShowView.vue'),
        meta: {
            isLoggedIn: true,
        },
    },
    {
        path: '/deleted_humans',
        name: 'DeletedHumanIndex',
        component: () => import(/* webpackChunkName: "deleted-humans-index" */ '../views/deleted_humans/IndexView.vue'),
        meta: {
            isLoggedIn: true,
        },
    },
    {
        path: '/entrances',
        name: 'EntranceIndex',
        component: () => import(/* webpackChunkName: "entrances-index" */ '../views/entrances/IndexView.vue'),
        meta: {
            isLoggedIn: true,
        },
    },
    {
        path: '/turnstiles',
        name: 'TurnstileIndex',
        component: () => import(/* webpackChunkName: "turnstiles-index" */ '../views/turnstiles/IndexView.vue'),
        meta: {
            isLoggedIn: true,
        },
    },
    {
        path: '/reports',
        name: 'ReportIndex',
        component: () => import(/* webpackChunkName: "reports-index" */ '../views/reports/IndexView.vue'),
        meta: {
            isLoggedIn: true,
        },
    },
    {
        path: '/moderation',
        name: 'ModerationIndex',
        component: () => import(/* webpackChunkName: "moderation-index" */ '../views/moderation/IndexView.vue'),
        meta: {
            isLoggedIn: true,
        },
    },
    {
        path: '/instructions',
        name: 'InstructionsIndex',
        component: () => import(/* webpackChunkName: "instructions-index" */ '../views/InstructionsView.vue'),
        meta: {
            isLoggedIn: true,
        },
    },
    {
        path: '/activity',
        name: 'ActivityIndex',
        component: () => import(/* webpackChunkName: "activity-index" */ '../views/activity/IndexView.vue'),
        meta: {
            isLoggedIn: true,
        },
    },
    {
        path: '/institutions',
        name: 'InstitutionIndex',
        component: () => import(/* webpackChunkName: "institution-index" */ '../views/institutions/IndexView.vue'),
        meta: {
            isLoggedIn: true,
        },
    },
    {
        path: '/403',
        name: 'Error403',
        component: () => import(/* webpackChunkName: "error-403" */ '../views/Page403View.vue'),
    },
    {
        path: '/500',
        name: 'Error500',
        component: () => import(/* webpackChunkName: "error-500" */ '../views/Page500View.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import(/* webpackChunkName: "page-404" */ '../views/Page404View.vue'),
        meta: { public: true },
    },
    {
        path: '/reset_password/:token',
        name: 'ResetPassword',
        component: () => import(/* webpackChunkName: "reset-password" */ '../views/reset_password/ResetPasswordView.vue'),
        meta: {
            title: 'Сброс пароля',
        },
    },
];
